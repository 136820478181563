import React, { useState, FC, useEffect } from "react";
import QrScanner from "react-qr-scanner";
import { useTranslation } from "react-i18next";

const QRScanner: FC<{ onScan: (result: object) => void }> = ({ onScan }) => {
  const { t } = useTranslation();

  const [result, setResult] = useState<object | null>(null);
  const [isCameraSupported, setIsCameraSupported] = useState<boolean>(false);

  useEffect(() => {
    if (
      typeof navigator.mediaDevices !== "undefined" &&
      typeof navigator.mediaDevices.getUserMedia === "function"
    ) {
      setIsCameraSupported(true);
    } else {
      handleError({
        name: t("qrScanner.errors.cameraNotSupportedError"),
        message: t("qrScanner.errors.cameraNotSupportedErrorMessage"),
      });
    }
  }, []);

  const handleScan = (data: any) => {
    if (data && (!result || (result as any).url !== data)) {
      const newResult = { url: data };
      setResult(newResult);
      onScan(newResult);
    }
  };

  const handleError = (error: any) => {
    const errorResult = {
      errorName: error.name,
      errorMessage: error.message,
      url: "",
    };
    setResult(errorResult);
    onScan(errorResult);
  };

  if (!isCameraSupported) {
    return <div>{t("qrScanner.errors.cameraNotSupportedErrorMessage")}</div>;
  }

  return (
    <div style={{ position: "static", padding: "0px" }}>
      <QrScanner
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: "100%", background: "black" }}
        constraints={{ video: { facingMode: "environment" } }}
      />
    </div>
  );
};

export default QRScanner;
